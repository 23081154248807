import  React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const ContactPage = () => (
    <Layout page="contact-page">
      <Seo title="CONTACT"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>CONTACT</span></h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title"><span>お問い合わせ</span></h3>
          </div>
          <div className="content-block">
            <p className="mb-12">お問い合わせいただく際は、必ず当社の「<Link to="/privacy/" className="c-attention">プライバシーポリシー</Link>」をご一読ください。<br />
              内容に同意していただけましたら、下記フォームに必要事項をご入力のうえ、「送信」ボタンをクリックしてください。なお、お問い合わせの内容によっては、ご返答が遅れる場合がございます。ご了承ください。</p>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdMS9xuiky20k_SzCVj-Wep4SSZhNZM9iQ-R3FlPv9_hPBNqw/viewform?embedded=true"
                width="100%" height="1000" frameBorder="0" marginHeight="0"
                marginWidth="0" title="お問い合わせ">読み込んでいます…
            </iframe>
          </div>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>お問い合わせ</li>
        </ul>
      </section>
    </Layout>
);

export default ContactPage;
